@require '/src/dev'

/*


      PAGEHOLDER
      - Basic horizontal positioning of the site inside banners.
      - Responsive behavior.
      - spacing between banner areas and content (panel)


*/
.pageholder
    pageholder();
    &--liquid
        max-width: none
    &--1400
        max-width: 1400px
