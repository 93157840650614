pageholderNoPadding()
    background-color: white
    padding: 0 // No space on small screens to accomodate content background that goes all the way to the edge of the screen
    margin: 0

    max-width: 1010px // Max usable width inside the banner-straitjacket (15 + 980 + 15 = 1010 which is the width of top banner )

    // Set these on body, or some higher out element than all pageholder type elements inkluding top and bottom. Keep this list to a minimum, it will be included on absolutely all pages atleast 3 times.
    &.content-width--liquid,
    .content-width--liquid &
        max-width: none

    &.content-width--1400,
    .content-width--1400 &
        max-width: 1400px

    &.content-width--1600,
    .content-width--1600 &
        max-width: 1600px

    +bp(min1300)
        margin-left: auto // Centers the page when there is room for a skyscraper on both sides of the main content
        margin-right: auto // Centers the page when there is room for a skyscraper on both sides of the main content

pageholder()
    pageholderNoPadding()
    +bp(min1300)
        padding: 0 15px // Sets 15px on each side of the content (these are the 30 px differene between the topbanner with and without horseshoe configuration)
